
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import echarts from 'echarts'
import { WeatherQualityList, WeatherQualityDetail } from '../../types/weatherQuality.d'
import { directionTransition, drawPolygon } from '../../utils/formatData'

@Component({
  name: 'DetailMap'
})
export default class DetailMap extends Vue {
  @Prop() radio!: string
  private drawer = false

  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private markers: Array<AMap.Marker> = []

  private onlineImg = require('@/assets/icon/weatherQuality/icon_map_huanjingjiankong_zaixian.svg')
  private onlineImgCursor = require('@/assets/icon/weatherQuality/icon_map_huanjingjiankong_zaixian_dianji.svg')
  private offImg = require('@/assets/icon/weatherQuality/icon_map_huanjingjiankong_lixian.svg')
  private offImgCursor = require('@/assets/icon/weatherQuality/icon_map_huanjingjiankong_lixian_dianji.svg')

  private deviceList: Array<WeatherQualityDetail> = []
  private drawerData: WeatherQualityDetail | null = null

  private paramList: Array<{ icon: string; name: string; value: string }> = [{
    icon: 'wendu',
    name: '温度(°C)',
    value: ''
  }, {
    icon: 'shidu',
    name: '湿度(%RH)',
    value: ''
  }, {
    icon: 'jiangshuiliang',
    name: '降雨量(mm)',
    value: ''
  }, {
    icon: 'qiya',
    name: '气压(kPa)',
    value: ''
  }, {
    icon: 'fengxiang',
    name: '风向',
    value: ''
  }, {
    icon: 'pm25',
    name: 'PM2.5(μg/m³)',
    value: ''
  }, {
    icon: 'pm25',
    name: '总悬浮颗粒(μg/m³)',
    value: ''
  }, {
    icon: 'zaoyin',
    name: '噪音(db)',
    value: ''
  }, {
    icon: 'fengsu',
    name: '风速(m/s)',
    value: ''
  }, {
    icon: 'fuyang',
    name: '负氧离子(个/cm³)',
    value: ''
  }, {
    icon: 'pm10',
    name: 'PM10(μg/m³)',
    value: ''
  }, {
    icon: 'eryanghuatan',
    name: '二氧化碳(ppm)',
    value: ''
  }, {
    icon: 'guangzhao',
    name: '光照(Lux)',
    value: ''
  }, {
    icon: 'taiyangzongfushe',
    name: '太阳总辐射(mwh/cm²)',
    value: ''
  }]

  private pm25StatusArr = [
    {
      name: '优',
      color: 'rgba(82, 196, 26, 1)'
    },
    {
      name: '良',
      color: 'rgba(248, 221, 0, 1)'
    },
    {
      name: '轻度污染',
      color: 'rgba(255, 193, 23, 1)'
    },
    {
      name: '中度污染',
      color: 'rgba(255, 113, 23, 1)'
    },
    {
      name: '重度污染',
      color: 'rgba(163, 14, 220, 1)'
    },
    {
      name: '严重污染',
      color: 'rgba(132, 2, 71, 1)'
    }
  ]

  private echartsShow = true

  get pm25StatusColor () {
    let color = ''
    this.pm25StatusArr.map((item: {name: string;color: string}) => {
      if (this.drawerData && item.name === this.drawerData.pm25Status) {
        color = item.color
      }
    })
    return color
  }

  get projectId () {
    return this.$route.params.projectId as string
  }

  @Watch('radio')
  radioChange (value: string) {
    value === 'map' && !this.map && this.getProjectLocation()
  }

  created () {
    this.radio === 'map' && this.getProjectLocation()
  }

  destroyed (): void {
    if (this.markers) {
      this.markers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.clearMap()
    }
  }

  // 获取项目信息及项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.$nextTick(() => {
        this.loadMap(res.projectInfo, res.projectLocation)
      })
    })
  }

  // 获取设备信息
  getDeviceList () {
    this.$axios.get<WeatherQualityList>(this.$apis.weatherQuality.selectWeatherQuality, {
      projectId: this.projectId
    }).then((res) => {
      this.deviceList = res.devices || []
      const data = [{
        name: '在线',
        value: res.onlineAmount
      }, {
        name: '离线',
        value: res.offLineAmount
      }]
      this.echartsShow = false
      this.drawEchart(data)
      this.drawMarker(this.deviceList)
    })
  }

  // 在线离线图
  drawEchart (data: Array<{ name: string; value: string }>) {
    this.echartsShow = true
    const pieEchart: any = echarts.init(this.$refs.echarts as any)
    const option = {
      title: {
        text: '状态统计',
        textStyle: {
          align: 'center',
          color: '#000',
          fontSize: 14,
          fontWeight: 'bold'
        },
        top: 20,
        left: 24
      },
      color: ['#1AD1B0', '#D2D2D2'],
      legend: {
        icon: 'circle',
        itemHeight: 6,
        show: true,
        itemGap: 15,
        right: 20,
        top: '45%',
        orient: 'vertical',
        textStyle: {
          fontSize: 14 // 字体大小
        },
        formatter: (item: string) => {
          return item === '在线' ? `${item}  ${data[0].value}` : `${item}  ${data[1].value}`
        }
      },

      series: [{
        name: '',
        type: 'pie',
        clockWise: true,
        radius: ['38%', '65%'],
        center: ['35%', '58%'],
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        hoverAnimation: false,
        data: data
      }]
    }
    pieEchart.setOption(option)
  }

  // 渲染地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.ploygons = drawPolygon(this.map, list || [], null, this.drawerClose)

      // 获取设备信息
      this.getDeviceList()

      // 点击地图关闭抽屉
      this.map.on('click', this.drawerClose)
    })
  }

  // 获取图片
  getIcon (deviceStatus: string, isCurrent: boolean) {
    if (!isCurrent) {
      return (deviceStatus === '1' ? this.onlineImg : this.offImg)
    } else {
      return deviceStatus === '1' ? this.onlineImgCursor : this.offImgCursor
    }
  }

  // 地图打点
  drawMarker (list: Array<WeatherQualityDetail>) {
    this.markers = []
    if (list && list.length > 0) {
      // 循坏将设备marker添加到地图
      list.forEach((item: WeatherQualityDetail) => {
        if (item.longitude && item.latitude) {
          const icon = this.getIcon(item.deviceStatus, false)
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: icon
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(Number(item.longitude), Number(item.latitude))
          })
          marker.detail = item
          marker.on('click', this.drawerOpen)
          this.map && this.map.add(marker)
          this.markers.push(marker)
        }
      })
    }
  }

  // 抽屉打开
  drawerOpen (e: any) {
    console.log(this.pm25StatusColor)
    if (this.drawerData === null || (this.drawerData && this.drawerData.deviceId !== e.target.detail.deviceId)) {
      if (this.drawerData && this.drawerData.deviceId !== e.target.detail.deviceId) {
        this.drawerClose()
      }
      this.drawerData = e.target.detail
      this.paramList[0].value = e.target.detail.temperature
      this.paramList[1].value = e.target.detail.humidity
      this.paramList[2].value = e.target.detail.precipitation
      this.paramList[3].value = e.target.detail.pressure
      this.paramList[4].value = directionTransition(e.target.detail.direction)
      this.paramList[5].value = e.target.detail.pm25
      this.paramList[6].value = e.target.detail.tsp
      this.paramList[7].value = e.target.detail.noise
      this.paramList[8].value = e.target.detail.speed
      this.paramList[9].value = e.target.detail.negativeOxyge
      this.paramList[10].value = e.target.detail.pm10
      this.paramList[11].value = e.target.detail.co2
      this.paramList[12].value = e.target.detail.lux
      this.paramList[13].value = e.target.detail.zfs
      // 点击切换大图标
      const iconClick = this.getIcon(e.target.detail.deviceStatus, true)
      e.target.setOffset(new AMap.Pixel(-22, -64))
      e.target.setIcon(iconClick)
    }
    this.drawer = true
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      // 将图标替换
      if (this.map && this.drawerData) {
        const deviceId = this.drawerData.deviceId || ''
        const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.detail.deviceId === deviceId)
        const icon = this.getIcon(this.drawerData.deviceStatus, false)
        const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
        marker.setOffset(new AMap.Pixel(-18, -48))
        marker.setIcon(icon)
      }

      // 清空抽屉
      this.drawerData = null
    }
  }
}
